import React from "react";
import Grow from "../../component/grow/Grow";
import mark from "../../images/Vector.svg";
import "./website.css";
import Implement from "../../component/implement/Implement";
import Other from "../../component/oather/Other";
import Shadow from "../../component/shadow/Shadow";
import Some from "../../component/some/Some";

export default function Website() {
  return (
    <div className="website">
      <div className="homebody">
        <div className="width">
          <div className="talk">
            <div className="design" data-aos="fade-up" data-aos-duration="1000">
              <p>Website Design And Development</p>
              <p>
                At Enzo Agency, we understand that a business's website is the
                digital face of their brand. It is the first point of contact
                for many potential customers, and it plays a crucial role in
                driving business growth. That's why we offer a comprehensive
                website design package that includes everything you need to
                create a professional and effective online presence.
                <br />
                <br />
                Our team of expert designers and developers work closely with
                clients to create websites that not only look great but also
                perform well.
              </p>
              <ul>
                <li>
                  <img src={mark} alt="" />
                  Custom website design tailored to your brand and business
                  needs
                </li>
                <li>
                  <img src={mark} alt="" />
                  Responsive design for seamless viewing across all devices
                </li>
                <li>
                  <img src={mark} alt="" />
                  User-friendly interface and navigation for optimal user
                  experience
                </li>
              </ul>
            </div>
          </div>
          <div className="talk" data-aos="fade-up" data-aos-duration="1000">
            <Grow />
          </div>
        </div>
      </div>
      <div className="pattern"></div>

      <Shadow />

      <Implement />

      <div className="pattern2"></div>
      <Other />
      <Some />
    </div>
  );
}
