import React from "react";
import Grow from "../../component/grow/Grow";
import mark from "../../images/Vector.svg";
import image from "../../images/services/Activity Feed.png";
import bus from "../../images/Group 271.svg";
import image2 from "../../images/services/Stack Overflow.png";
import image3 from "../../images/services/User Group.png";
import image4 from "../../images/services/Video Gallery.png";
import image5 from "../../images/services/People.png";
import image1 from "../../images/services/Technology Lifestyle.png";

import "./social.css";
import walk from "../../images/services/dd.png";
import walk1 from "../../images/services/de.png";
import walk2 from "../../images/services/df.png";

export default function Social() {
  return (
    <div className="social">
      <div className="website">
        <div className="homebody">
          <div className="width">
            <div className="talk">
              <div
                className="design"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Social Media Marketing</p>
                <p>
                  At Enzo, we believe that social media marketing is not just
                  about generating likes and followers but about building
                  meaningful relationships with the target audience that can
                  translate into long-term business growth.
                  <br />
                  <br />
                  Our team also focuses on building a strong online presence for
                  the brand by regularly posting content and engaging with the
                  audience. We use various tools and techniques to track and
                  analyze the performance of social media campaigns and adjust
                  our strategies accordingly.
                </p>
                <ul>
                  <li>
                    <img src={mark} alt="" />
                    Increased Brand Awareness: Social media marketing provides
                    an excellent platform to increase brand visibility and reach
                    a wider audience.
                  </li>
                  <li>
                    <img src={mark} alt="" />
                    Enhanced Customer Engagement: Social media platforms offer
                    direct channels of communication between your brand and your
                    audience.
                  </li>
                  <li>
                    <img src={mark} alt="" />
                    Increased Website Traffic and Conversions: Social media
                    marketing can drive significant traffic to your website.
                  </li>
                </ul>
              </div>
            </div>
            <div className="talk" data-aos="fade-up" data-aos-duration="1000">
              <Grow />
            </div>
          </div>
        </div>

        <div className="pattern"></div>
        {/* <Shadow /> */}

        <>
          <div className="width" data-aos="fade-up" data-aos-duration="1000">
            <div className="shadow ">
              <p>Some businesses We’ve Worked with</p>
              <img src={bus} alt="" />
            </div>
          </div>
        </>

        {/* <Implement /> */}
        <div className="implement">
          <div className="width">
            <div className="talk">
              <p data-aos="fade-up" data-aos-duration="1000">
                Building Brand Authenticity
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                Social media has become an integral part of our lives, with
                millions of users logging into various platforms every day. As a
                digital agency, Enzo understands the power of social media and
                the impact it can have on a business's growth. We offer
                comprehensive social media marketing services that are designed
                to help businesses reach their target audience and achieve their
                marketing goals.
                <br />
                <br />
                Our social media marketing team is highly experienced and
                skilled in creating customized social media strategies that are
                tailored to each business's specific needs. We begin by
                conducting a thorough analysis of the client's industry and
                target audience to identify the most effective social media
                platforms for their business. We then create engaging and
                relevant content that resonates with the target audience and
                aligns with the brand's messaging.
              </p>
            </div>

            <div className="usercenter">
              <div className="pictalk">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={walk}
                  alt=""
                />
              </div>
              <div
                className="pictalk"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Social Media Management</p>
                <p>
                  Social media management involves creating and managing social
                  media accounts on behalf of businesses to increase their
                  online presence, drive traffic, and engage with customers. At
                  Enzo Agency, our social media experts develop a customized
                  social media strategy for each business, including content
                  creation, community management, and reporting and analysis.
                </p>
              </div>
            </div>

            <div className="usercenter reverse">
              <div className="pictalk">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={walk1}
                  alt=""
                />
              </div>
              <div
                className="pictalk"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Influencer Marketing</p>
                <p>
                  Influencer marketing involves partnering with influential
                  individuals on social media to promote a business's products
                  or services. At Enzo Agency, we help businesses identify the
                  right influencers to work with and develop creative campaigns
                  that align with their brand values. Our team manages the
                  entire influencer marketing process, from outreach to content
                  creation and performance tracking
                </p>
              </div>
            </div>

            <div className="usercenter">
              <div className="pictalk">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={walk2}
                  alt=""
                />
              </div>
              <div
                className="pictalk"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Social Media Promotion</p>
                <p>
                  Social media advertising is a paid form of digital advertising
                  that promotes a business's products or services on social
                  media platforms. Enzo Agency's social media advertising team
                  creates targeted campaigns that reach the right audience,
                  increase brand awareness, and drive conversions. We use a
                  data-driven approach to optimize campaigns and deliver
                  measurable results.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="pattern2"></div>

        {/* <Other /> */}
        <div className="other">
          <div className="width">
            <div className="talk">
              <p data-aos="fade-up" data-aos-duration="1000">
                Other Services
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                Some Other Services We Render To Our Clients In Respect To{" "}
                <span> Social Media Marketing</span>
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                At Enzo digital marketing agency, our mission is to help
                businesses achieve their online objectives through effective and
                innovative digital marketing strategies. We strive to be a
                trusted partner to our clients, delivering measurable results
                and providing exceptional customer service.{" "}
              </p>
            </div>

            <div className="gridtry">
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Social Media Strategy Development
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  This service includes developing a customized social media
                  strategy for your clients, based on their business goals,
                  target audience, and industry trends.
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image2}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Social Media Listening and Monitoring
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  This service involves monitoring social media channels for
                  mentions of your client's brand, products, or industry, and
                  responding to customer inquiries or comments in a timely
                  manner.{" "}
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image3}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Social Media Advertising
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  Social media advertising involves creating and executing paid
                  ad campaigns on social media platforms to reach a larger
                  audience and drive conversions.
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image4}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Influencer Marketing
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  Influencer marketing involves partnering with social media
                  influencers to promote your client's brand or products to
                  their followers.
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image5}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Social Media Account Management
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  This service involves managing your client's social media
                  accounts, including content creation, posting, and engagement.
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image1}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Graphics Design and Brand Identity
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  From concept to creation, our graphics design service crafts
                  visually stunning and impactful designs that leave a lasting
                  impression on your audience.{" "}
                </p>
              </div>
              {/* <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image1}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Social Media Content Creation
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  Social media content creation involves creating visual and
                  written content, such as images, videos, and captions, to be
                  shared on social media platforms.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
