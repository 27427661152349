import React from "react";
import icon1 from "../../images/home/Group 117.png";
import icon2 from "../../images/home/2.png";
import icon3 from "../../images/home/3.png";
import icon4 from "../../images/home/4.png";
import icon5 from "../../images/home/5.png";
import icon6 from "../../images/home/6.png";
import think from "../../images/home/Rectangle 8.png";
import "./long.css";
import arrow from "../../images/Group 118.svg";
import { Link } from "react-router-dom";

export default function Long() {
  return (
    <>
      <div className="long">
        <div className="width">
          <div className="talk" data-aos="fade-up" data-aos-duration="1000">
            <p>About Us</p>
            <p>
              The Best <span> Digital Agency</span> For Business Solution
            </p>
            <p>
              At Enzo Digital Marketing Agency, we firmly believe that digital
              marketing is far from a one-size-fits-all solution. We recognize
              that each business is unique, with its own distinct goals,
              challenges, and target audience. That's why we are dedicated to
              delivering a tailored approach to digital marketing, ensuring our
              services align with the specific needs of every client.
              <br />
              <br />
              Our team consists of experienced digital marketing professionals
              who stay up-to-date with the latest trends and best practices. We
              understand that the digital landscape is constantly evolving, and
              we strive to provide our clients with the most effective
              strategies and tactics to drive their success.
            </p>
            <p>
              - <span> C.E.O,</span> Enzo Agency
            </p>
            <Link to="/about">
              <button>
                <h5> Learn More </h5> <img src={arrow} alt="" />{" "}
              </button>
            </Link>
          </div>
          <div className="talk" data-aos="fade-up" data-aos-duration="1000">
            <img src={think} alt="" />
          </div>
        </div>
      </div>

      <div className="number">
        <div className="width">
          <p data-aos="fade-up" data-aos-duration="1000">
            Let the Number Speak For Themselves
          </p>
          <div className="speak">
            <div className="2k" data-aos="fade-up" data-aos-duration="1000">
              <p>2K</p>
              <p>Global customers</p>
            </div>
            <div className="2k" data-aos="fade-up" data-aos-duration="1000">
              <p>1K+</p>
              <p>Completed Projects</p>
            </div>
            <div className="2k" data-aos="fade-up" data-aos-duration="1000">
              <p>959+</p>
              <p>Customers Reviews</p>
            </div>
          </div>
        </div>
      </div>

      <div className="why">
        <div className="width">
          <p className="g" data-aos="fade-up" data-aos-duration="1000">
            Why Partner with Enzo?
          </p>

          <div className="result">
            <div className="driven" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon">
                <img src={icon1} alt="" />
              </div>
              <div className="talk">
                <p>Results-Driven Strategies</p>
                <p>
                  Enzo agency uses data-driven strategies to create effective
                  marketing campaigns that deliver results. This means
                  businesses can trust that their marketing investments will
                  yield measurable results that can help them achieve their
                  goals.
                </p>
              </div>
            </div>
            <div className="driven" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon">
                <img src={icon2} alt="" />
              </div>
              <div className="talk">
                <p>Scalable and Flexible</p>
                <p>
                  Businesses can rely on your agency to provide them with the
                  resources they need to succeed, whether they need to scale up
                  their marketing efforts during peak seasons or scale back
                  during slow periods.
                </p>
              </div>
            </div>
            <div className="driven" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon">
                <img src={icon3} alt="" />
              </div>
              <div className="talk">
                <p>Experience and Expertise </p>
                <p>
                  Enzo digital agency has years of experience and specialized
                  expertise in various areas of digital marketing. Our team of
                  experts can provide businesses with customized solutions that
                  are tailored to their specific needs and goals.
                </p>
              </div>
            </div>
            <div className="driven" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon">
                <img src={icon4} alt="" />
              </div>
              <div className="talk">
                <p>Customized Solution</p>
                <p>
                  Our agency provides businesses with customized solutions that
                  are tailored to their specific needs and goals. This means
                  businesses can benefit from personalized marketing strategies
                  that are designed to achieve their unique objectives.
                </p>
              </div>
            </div>
            <div className="driven" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon">
                <img src={icon5} alt="" />
              </div>
              <div className="talk">
                <p>Access to the Latest Technologies</p>
                <p>
                  Our agency invests in the latest tools and technologies to
                  stay ahead of the curve. By working with your agency,
                  businesses gain access to these tools and technologies, which
                  can help them streamline their marketing efforts, track their
                  progress, and measure their success.
                </p>
              </div>
            </div>
            <div className="driven" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon">
                <img src={icon6} alt="" />
              </div>
              <div className="talk">
                <p>Partnership Approach</p>
                <p>
                  Our agency takes a partnership approach to working with
                  businesses. This means you prioritize building long-term
                  relationships with your clients, working closely with them to
                  achieve their goals and helping them succeed in the long run.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
