import React from "react";
import "./ourservice.css";
import eclipse from "../../images/home/Group 210.png";
import eclipse2 from "../../images/home/Group 211.png";
import eclipse3 from "../../images/home/Group 212.png";
import arrow from "../../images/Group 118.svg";
import { Link } from "react-router-dom";

export default function Ourservice() {
  return (
    <div className="ourservice">
      <div className="width">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          Our Services
        </h1>
        <div className="web">
          <div className="design" data-aos="fade-up" data-aos-duration="1000">
            <div className="icon">
              <img src={eclipse} alt="" />
            </div>
            <div className="talk">
              <p>Website Design And Development</p>
              <p>
                We help provide businesses with everything they need to create a
                new website or redesign an existing one. It includes website
                design and development, responsive design, content creation, SEO
                optimization, quality assurance and testing, and post-launch
                support.
              </p>
            </div>
            <Link to="/website">
              <button>
                <h5> Explore </h5> <img src={arrow} alt="" />
              </button>
            </Link>
          </div>
          <div className="design" data-aos="fade-up" data-aos-duration="1000">
            <div className="icon">
              <img src={eclipse2} alt="" />
            </div>
            <div className="talk">
              <p>Social Media Marketing </p>
              <p>
                We help our clients establish and maintain a strong presence on
                social media platforms such as Facebook, Instagram, Twitter, and
                LinkedIn. We create engaging content, run targeted ad campaigns,
                and monitor and respond to customer feedback to build brand
                awareness and loyalty.
              </p>
            </div>
            <Link to="/social">
              <button>
                <h5> Explore </h5> <img src={arrow} alt="" />
              </button>
            </Link>
          </div>
          <div className="design" data-aos="fade-up" data-aos-duration="1000">
            <div className="icon">
              <img src={eclipse3} alt="" />
            </div>
            <div className="talk">
              <p>E-Commerce SEO</p>
              <p>
                We use a variety of techniques to optimize our clients' websites
                for search engines, improving their visibility and rankings in
                search results. We conduct in-depth keyword research, optimize
                website content and structure, and build high-quality backlinks
                to improve our clients' online presence.
              </p>
            </div>
            <Link to="/seo">
              <button>
                <h5> Explore </h5> <img src={arrow} alt="" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
