import React from "react";
import arrow from "../../images/Group 118.svg";
import shake from "../../images/about/Rectangle 8 (1).png";
import "./enter.css";
import { Link } from "react-router-dom";

export default function Enter() {
  return (
    <div className="enter">
      <div className="width">
        <div className="pictalk">
          <div className="pic" data-aos="fade-right" data-aos-duration="2000">
            <img src={shake} alt="" />
          </div>
          <div className="talk" data-aos="fade-left" data-aos-duration="2000">
            <div className="content">
              <p>What We Offer To Your Business</p>
              <p>
                We can offer a range of services and solutions to businesses to
                help them achieve their marketing goals, including website
                design and development, digital advertising, social media
                management, content creation, and more.
                <br />
                <br />
                With our expertise and experience, we can help businesses reach
                and engage their target audience, build their brand, and grow
                their business over time.
              </p>
              <Link to="/contact">
                <button>
                  <h5>Contact Us </h5> <img src={arrow} alt="" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
