import React from "react";
import Grow from "../../component/grow/Grow";
import mark from "../../images/Vector.svg";
import image from "../../images/services/Increase.png";
import image2 from "../../images/services/Hand Drawn Star.png";
import image3 from "../../images/services/Web Advertising.png";
import image4 from "../../images/services/Omnichannel.png";
import image5 from "../../images/services/Search Bar.png";
import image1 from "../../images/services/Positive Dynamic.png";

import "./social.css";
import walk from "../../images/services/unsplash_PMxT0XtQ--A.png";
import Shadow from "../../component/shadow/Shadow";

export default function Seo() {
  return (
    <div className="social">
      <div className="website">
        <div className="homebody">
          <div className="width">
            <div className="talk">
              <div
                className="design"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Search Engine Optimization (SEO)</p>
                <p>
                  At Enzo, we specialize in SEO services to boost brands' online
                  visibility. Our offerings include tailored SEO strategies,
                  on-page and technical optimizations, keyword-optimized content
                  creation, high-quality backlink building, and ongoing
                  monitoring and consultation. With our expertise, brands can
                  attract organic traffic, improve search engine rankings, and
                  achieve long-term online success. Our goal is to help brands
                  optimize their online presence and drive meaningful results
                  through effective SEO strategies and tactics.
                </p>
                <ul>
                  <li>
                    <img src={mark} alt="" />
                    Increased Organic Traffic: SEO helps improve your website's
                    visibility in search engine results, leading to higher
                    organic traffic
                  </li>
                  <li>
                    <img src={mark} alt="" />
                    Improved User Experience: SEO involves optimizing your
                    website's structure, content, and usability to provide a
                    seamless user experience.
                  </li>
                  <li>
                    <img src={mark} alt="" />
                    Long-Term Cost Effectiveness: Unlike paid advertising
                    methods, SEO offers long-term benefits and sustainable
                    results.
                  </li>
                </ul>
              </div>
            </div>
            <div className="talk" data-aos="fade-up" data-aos-duration="1000">
              <Grow />
            </div>
          </div>
        </div>

        <div className="pattern"></div>

        <Shadow />

        {/* <Implement /> */}
        <div className="implement">
          <div className="width">
            <div className="talk">
              <p data-aos="fade-up" data-aos-duration="1000">
                Brand Awareness and Visibility
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                As a digital agency, we understand the importance of brand
                awareness and visibility in SEO. Our services are designed to
                optimize websites and improve search engine rankings, ensuring
                maximum visibility to target audiences. Through strategic
                keyword research, on-page optimization, technical enhancements,
                and content creation, we enhance brand visibility in search
                results. We also focus on building high-quality backlinks and
                leveraging social media platforms to expand brand reach. By
                improving brand awareness and visibility, we help our clients
                attract organic traffic, gain credibility, increase conversions,
                and ultimately drive business growth. Our dedicated team of
                experts is committed to delivering results that make a lasting
                impact on brand recognition and visibility.
              </p>
            </div>

            <div className="usercenter">
              <div className="pictalk">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={walk}
                  alt=""
                />
              </div>
              <div
                className="pictalk"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Keyword Research and Optimization</p>
                <p>
                  Keywords are the foundation of successful SEO. Our team
                  conducts in-depth keyword research to identify the most
                  relevant and high-value keywords for your business. We
                  strategically incorporate these keywords into your website's
                  content, ensuring that search engines and users can easily
                  find and understand your offerings.
                </p>
              </div>
            </div>

            <div className="usercenter reverse">
              <div className="pictalk">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={walk}
                  alt=""
                />
              </div>
              <div
                className="pictalk"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Comprehensive Website Analysis</p>
                <p>
                  We begin by conducting a thorough analysis of your website,
                  examining its structure, content, and technical aspects. Our
                  experts identify areas that need improvement and develop a
                  tailored strategy to address them. From optimizing meta tags
                  to enhancing website speed and performance, we leave no stone
                  unturned in our quest to boost your website's SEO potential.
                </p>
              </div>
            </div>

            <div className="usercenter">
              <div className="pictalk">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={walk}
                  alt=""
                />
              </div>
              <div
                className="pictalk"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p>Mobile-Friendly Design</p>
                <p>
                  With the majority of internet users accessing websites on
                  mobile devices, having a mobile-friendly website is no longer
                  optional. Google prioritizes mobile-friendly websites in its
                  search results. Make sure your website is responsive and
                  optimized for different screen sizes. Optimize loading speed
                  by compressing images, minimizing code, and utilizing caching
                  techniques. Conduct regular mobile testing to ensure a
                  seamless experience for mobile users.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="pattern2"></div>

        {/* <Other /> */}
        <div className="other">
          <div className="width">
            <div className="talk">
              <p data-aos="fade-up" data-aos-duration="1000">
                Other Services
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                Some Other Services We Render To Our Clients In Respect To{" "}
                <span>Search Engine Optimization </span>
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                At Enzo digital marketing agency, our mission is to help
                businesses achieve their online objectives through effective and
                innovative digital marketing strategies. We strive to be a
                trusted partner to our clients, delivering measurable results
                and providing exceptional customer service.{" "}
              </p>
            </div>

            <div className="gridtry">
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  SEO Strategy Development
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  We develop a tailored SEO strategy by conducting keyword
                  research, competitor analysis, and identifying optimization
                  opportunities to align with your business goals and target
                  audience.{" "}
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image1}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  On-Page Optimization
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  We optimize your website's on-page elements, including meta
                  tags, headings, URLs, and content, to enhance search engine
                  visibility and user experience, ensuring effective indexing
                  and ranking.
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image2}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Local SEO Optimization
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  For local businesses, we optimize your online presence by
                  optimizing Google My Business listings, managing reviews, and
                  improving local citations to boost visibility in local search
                  results and attract local customers.
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image3}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Link Building and Outreach
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  We build a strong backlink profile with high-quality backlinks
                  from reputable websites through strategies like outreach,
                  guest blogging, and content promotion, boosting your website's
                  authority and visibility.
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image4}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Content Creation and Optimization
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  We create engaging, keyword-optimized content to attract
                  organic traffic, including blog posts, articles, and relevant
                  content that addresses user queries and provides valuable
                  information.{" "}
                </p>
              </div>
              <div className="box">
                <img
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  src={image5}
                  alt=""
                />
                <p data-aos="fade-up" data-aos-duration="1000">
                  Search Engine Optimization
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  SEO involves optimizing websites to improve their search
                  engine ranking and drive more organic traffic.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
