import React from "react";
import logo from "../../images/Enzo logo 1.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import twitter from "../../images/twitter.png";
import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="width">
        <div className="four">
          <img src={logo} alt="" />
          <p>kumputaisaac@gmail.com</p>
          <br />
          <p>08139927900</p>
        </div>
        <div className="four">
          <h1>Our Service </h1>
          <ul>
            <li>
              {" "}
              <Link to="/website">Website Design Package</Link>{" "}
            </li>
            <li>
              {" "}
              <Link to="/social"> Social Media Advertisement</Link>
            </li>
            <li>
              {" "}
              <Link to="/seo">E-Commerce SEO</Link>{" "}
            </li>
          </ul>
        </div>
        <div className="four">
          <h1>Quick Links</h1>
          <ul>
            <li>
              {" "}
              <Link to="/">Home</Link>
            </li>
            <li>
              {" "}
              <Link to="/about">About us</Link>
            </li>
            <li>Client Review </li>
            <li>
              {" "}
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="four">
          <h1>Socials</h1>
          <div className="social">
            <a
              href="https://www.instagram.com/enzo_digital_official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="" />
            </a>
            <a
              href="https://twitter.com/enzo_digital_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="" />
            </a>

            <img src={facebook} alt="" />
          </div>
        </div>
      </div>
      <p className="c">&copy; 2023 Enzo Digital Marketing Agency</p>
    </div>
  );
}
