import two from "../../images/services/unsplash_rwF_pJRWhAI (1).png";
import three from "../../images/services/unsplash_4XK2oKKvzVU.png";
import React from "react";
import walk from "../../images/services/unsplash_PMxT0XtQ--A.png";
import "./implement.css";

export default function Implement() {
  return (
    <div className="implement">
      <div className="width">
        <div className="talk">
          <p data-aos="fade-up" data-aos-duration="1000">
            Designing and Implementation of Websites
          </p>
          <p data-aos="fade-up" data-aos-duration="1000">
            With over 4 years of experience in website design and development,
            our team of experts has worked with businesses of all sizes and
            industries to create websites that not only look great but also
            perform well. We use the latest design trends and technologies to
            deliver websites that are both visually appealing and functional.
            <br />
            <br />
            Whether you need a simple website or a complex e-commerce platform,
            we have the skills and expertise to bring your vision to life. Let
            us help you transform your online presence and take your business to
            the next level. Contact us today to learn more about our website
            design services.
          </p>
        </div>

        <div className="usercenter">
          <div className="pictalk" data-aos="fade-up" data-aos-duration="1000">
            <img src={walk} alt="" />
          </div>
          <div className="pictalk" data-aos="fade-up" data-aos-duration="1000">
            <p>User-Centered Design</p>
            <p>
              Our team of designers and developers work together to ensure that
              your website is not only visually appealing, but also easy to use
              and navigate for your target audience. We conduct extensive
              research and analysis to gain insights into your users' behavior,
              preferences, and needs, and use this information to inform our
              design decisions. This approach allows us to create a website that
              not only looks great, but also provides a seamless and enjoyable
              user experience.
            </p>
          </div>
        </div>

        <div className="usercenter reverse">
          <div className="pictalk" data-aos="fade-up" data-aos-duration="1000">
            <img src={two} alt="" />
          </div>
          <div className="pictalk" data-aos="fade-up" data-aos-duration="1000">
            <p>Responsive Website</p>
            <p>
              In today's mobile-first world, having a website that is optimized
              for different devices and screen sizes is crucial. That's why all
              of our website design packages include responsive design as
              standard. This means that your website will automatically adapt to
              different devices, whether it's a desktop, tablet, or smartphone.
              This ensures that your website looks great and functions
              effectively, regardless of the device your users are using to
              access it.
            </p>
          </div>
        </div>

        <div className="usercenter">
          <div className="pictalk" data-aos="fade-up" data-aos-duration="1000">
            <img src={three} alt="" />
          </div>
          <div className="pictalk" data-aos="fade-up" data-aos-duration="1000">
            <p>Ongoing Support</p>
            <p>
              At Enzo, we believe that our job doesn't end once your website
              goes live. That's why we offer ongoing support to ensure that your
              website is always up-to-date and functioning effectively. Our
              support team is available to assist you with any issues or
              questions you may have, and we also offer regular maintenance and
              updates to keep your website running smoothly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
