import React, { useState } from "react";
import arrow from "../../images/Group 118.svg";
import "./grow.css";
import ReactGA from "react-ga";

export default function Grow() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [Phonenumber, setPhonenumber] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      message,
      Phonenumber,
    };

    const response = await fetch("https://formspree.io/f/xoqzzkjb", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert("Your message has been sent, thank you!");
      setEmail("");
      setName("");
      setPhonenumber("");
    } else {
      alert("Sorry, there was an error. Please try again .");
    }

    ReactGA.event({
      category: "first form submit",
      action: "submit form",
    });
  };

  const handleselect = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className="grow">
      <p>GROW YOUR BRAND </p>
      <p>with ENZO Agency</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          placeholder="Full Name"
        />
        <input
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder="E-mail "
        />
        <input
          type="number"
          value={Phonenumber}
          onChange={(e) => setPhonenumber(e.target.value)}
          required
          placeholder="Phone Number"
        />
        <select onChange={handleselect}>
          <option value="" selected disabled hidden>
            Problem you are trying to solve
          </option>
          <option value="Build a Website">Build a Website</option>
          <option value="Social Media Marketing">Social Media Marketing</option>
          <option value="Increase Online Presence">
            Increase Online Presence
          </option>
        </select>
        <div>
          <button>
            <h5> Submit</h5> <img src={arrow} alt="" />
          </button>
        </div>
      </form>
    </div>
  );
}
