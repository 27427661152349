import React from "react";
import image from "../../images/about/Rectangle 15 (1).png";
import arrow from "../../images/Group 118.svg";
import "./leading.css";
import { Link } from "react-router-dom";

export default function Leading() {
  return (
    <div className="leading">
      <div className="width">
        <div className="talk">
          <p data-aos="fade-up" data-aos-duration="1000">
            Our Leading & Creative Team
          </p>
          <p data-aos="fade-up" data-aos-duration="1000">
            Our team at Enzo digital marketing agency, is made up of experienced
            and passionate digital marketing professionals who are committed to
            delivering exceptional results for our clients. We are dedicated to
            staying up-to-date with the latest digital marketing trends and best
            practices to ensure that we are always delivering the most effective
            solutions for our clients.
            <br />
            <br />
            We are committed to investing in the professional development of our
            team members. We provide ongoing training and education to ensure
            that our team stays up-to-date with the latest digital marketing
            trends and best practices.
          </p>
          <Link to="/contact">
            <button data-aos="fade-up" data-aos-duration="1000">
              <h5>Contact Us</h5> <img src={arrow} alt="" />{" "}
            </button>
          </Link>
        </div>
        <div className="talk" data-aos="fade-up" data-aos-duration="1000">
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
}
