import React from "react";
import "./test.css";

export default function Testimonial() {
  return (
    <div className="testimonial">
      <div className="width">
        <div className="long">
          <div className="talk">
            <p data-aos="fade-up" data-aos-duration="1000">
              Testimonials
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              Some Of Our Clients Review
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
