import React from "react";
import image from "../../images/Group 247.png";
import "./shadow.css";

export default function Shadow() {
  return (
    <>
      <div className="width" data-aos="fade-up" data-aos-duration="1000">
        <div className="shadow ">
          <p>Some businesses We’ve Worked with</p>
          <img src={image} alt="" />
        </div>
      </div>
    </>
  );
}
