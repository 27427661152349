import React from "react";
import "./mission.css";

export default function Mission() {
  return (
    <div className="mission">
      <div className="width">
        <div className="talk">
          <p data-aos="fade-up" data-aos-duration="1000">
            Our Mission
          </p>
          <p data-aos="fade-up" data-aos-duration="1000">
            At Enzo digital marketing agency, our mission is to help businesses
            achieve their online objectives through effective and innovative
            digital marketing strategies. We strive to be a trusted partner to
            our clients, delivering measurable results and providing exceptional
            customer service.
          </p>
        </div>
        <div className="talk">
          <p data-aos="fade-up" data-aos-duration="1000">
            Our Vision
          </p>
          <p data-aos="fade-up" data-aos-duration="1000">
            Our vision at Enzo digital marketing agency is to be a leading
            digital marketing agency, recognized for our expertise, creativity,
            and commitment to delivering results. We aim to be a collaborative
            and supportive partner to our clients, helping them achieve their
            goals and grow their businesses through effective digital marketing
            strategies.
          </p>
        </div>
      </div>
    </div>
  );
}
