import React from "react";
import image from "../../images/services/Website.png";
import image1 from "../../images/services/Design.png";
import image2 from "../../images/services/Globe.png";
import image3 from "../../images/services/Table Top View.png";
import image4 from "../../images/services/Internet.png";
import image5 from "../../images/services/Layout.png";
import "./other.css";

export default function Other() {
  return (
    <div className="other">
      <div className="width">
        <div className="talk">
          <p data-aos="fade-up" data-aos-duration="1000">
            Other Services
          </p>
          <p data-aos="fade-up" data-aos-duration="1000">
            Some Other Services We Render To Our Clients In Respect To{" "}
            <span>Website Package</span>
          </p>
          <p data-aos="fade-up" data-aos-duration="1000">
            At Enzo digital marketing agency, our mission is to help businesses
            achieve their online objectives through effective and innovative
            digital marketing strategies. We strive to be a trusted partner to
            our clients, delivering measurable results and providing exceptional
            customer service.
          </p>
        </div>

        <div className="gridtry">
          <div className="box">
            <div className="image">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={image}
                alt=""
              />
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
              Website Development
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              This service includes designing and developing websites from
              scratch, using HTML, CSS, JavaScript, and other web technologies.
            </p>
          </div>
          <div className="box">
            <div className="image">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={image1}
                alt=""
              />
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
              Web Hosting
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              Web hosting involves providing hosting services for websites,
              ensuring they are secure, fast, and reliable.{" "}
            </p>
          </div>
          <div className="box">
            <div className="image">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={image2}
                alt=""
              />
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
              UI/UX Design
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              UI/UX design involves designing user interfaces and user
              experiences for websites and apps that are intuitive, visually
              appealing, and easy to use.{" "}
            </p>
          </div>
          <div className="box">
            <div className="image">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={image3}
                alt=""
              />
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
              Web Application Development
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              Custom web application development involves building web-based
              applications that are tailor-made for specific business needs.{" "}
            </p>
          </div>
          <div className="box">
            <div className="image">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={image4}
                alt=""
              />
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
              Content Management System Development
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              Web hosting involves providing hosting services for websites,
              ensuring they are secure, fast, and reliable.{" "}
            </p>
          </div>
          <div className="box">
            <div className="image">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={image5}
                alt=""
              />
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
              Search Engine Optimization
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              SEO involves optimizing websites to improve their search engine
              ranking and drive more organic traffic.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
