import React from "react";
import arrow from "../../images/Group 118.svg";
// import brand from "../../images/home/Rectangle 58.png";
import "./help.css";
import { Link } from "react-router-dom";

export default function Help() {
  return (
    <div className="help">
      <div className="width">
        <div className="two">
          <p data-aos="fade-up" data-aos-duration="1000">
            DO YOU HAVE A PROJECT? LET US HELP YOU GROW
          </p>
          <p data-aos="fade-up" data-aos-duration="1000">
            Contact us today and let our expert team help you achieve your goals
            with our digital marketing solutions.
          </p>
          <Link to="/contact">
            <button data-aos="fade-up" data-aos-duration="1000">
              {" "}
              Contact Us<h5> </h5> <img src={arrow} alt="" />
            </button>
          </Link>
        </div>
        <div className="two" data-aos="fade-up" data-aos-duration="1000">
          {/* <img src={brand} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
