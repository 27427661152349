import React, { useState } from "react";
import arrow from "../../images/Group 118.svg";
import "./contact.css";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [Phonenumber, setPhonenumber] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      message,
      Phonenumber,
    };

    const response = await fetch("https://formspree.io/f/xoqzzkjb", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert("Your message has been sent, thank you!");
      setEmail("");
      setName("");
      setPhonenumber("");
      setMessage("");
    } else {
      alert("Sorry, there was an error. Please try again .");
    }
  };

  return (
    <div className="contact">
      <div className="width">
        <h2>Let’s do amazing things together</h2>

        <form onSubmit={handleSubmit}>
          <p>Talk to us, we will be glad to help</p>
          <hr />
          <label htmlFor="">Full name*</label>
          <br />{" "}
          <input
            required
            value={name}
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your full Name"
          />
          <br />
          <label htmlFor="">Email address* </label>
          <br />{" "}
          <input
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email address "
          />
          <br />
          <label htmlFor="">Phone number*</label>
          <br />
          <input
            required
            value={Number}
            onChange={(e) => setPhonenumber(e.target.value)}
            type="number"
            placeholder="Enter your phone number "
          />
          <br />
          <label htmlFor="">Your message*</label> <br />
          <textarea
            name=""
            id=""
            cols="30"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="10"
            placeholder="Tell us about your idea, insight or project"
          ></textarea>
          <button>
            <h5>Submit</h5> <img src={arrow} alt="" />
          </button>
        </form>
      </div>
    </div>
  );
}
