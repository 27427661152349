import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Homebody from "../../component/homebody/Homebody";
import Ourservice from "../../component/ourservices/Ourservice";
import Sb from "../../component/sb/Sb";
import Long from "../../component/Long/Long";
import Testimonial from "../../component/Testimonial/Testimonial";

export default function Homepage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="homepage">
      <Homebody />
      <div className="pattern"></div>
      <Sb />
      <Long />
      <Ourservice />
      <div className="pattern2"></div>
      <Testimonial />
    </div>
  );
}
