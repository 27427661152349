import React, { useState } from "react";
import logo from "../../images/Enzo logo 1.png";
// import logo2 from "../../images/Enzo 12.jpg";
import arrow from "../../images/Group 118.svg";
import "./header.css";
import menu from "../../images/menu.png";
import close from "../../images/close-circle.svg";
import icon from "../../images/website logo.svg";
import icon2 from "../../images/social media logo.svg";
import icon3 from "../../images/seo ofcourse.svg";
import { Link } from "react-router-dom";

export default function Header() {
  const [hover, sethover] = useState(false);

  const [iscontent1, setcontent1] = useState(false);

  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const opencontent = () => {
    setcontent1(!iscontent1);
  };

  return (
    <div className="header" style={hover ? { backgroundColor: "#fbf8f6" } : {}}>
      <div className="width">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="talk">
          <ul>
            <li style={hover ? { color: "black" } : {}}>
              <Link to="/about">About us</Link>
            </li>
            <li
              onMouseEnter={() => sethover(true)}
              onMouseLeave={() => sethover(false)}
            >
              Our Services
              <div className="sub-menu dev">
                <div className="this">
                  <div className="four">Range of Services We Provide</div>
                  <div className="four devv">
                    <Link to="/website">
                      <div className="good">
                        <img src={icon} alt="" />
                      </div>
                      Website Design <br /> and Development
                    </Link>
                  </div>
                  <div className="four ">
                    <Link to="/social">
                      <div className="good">
                        <img src={icon2} alt="" />
                      </div>
                      Social Media <br /> Marketing
                    </Link>
                  </div>
                  <div className="four">
                    <Link to="/seo">
                      <div className="good">
                        <img src={icon3} alt="" />
                      </div>
                      SEO and others
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li style={hover ? { color: "black" } : {}}>Blog</li>
            <li>
              <Link to="/contact">
                <button>
                  <h5>Contact Us </h5> <img src={arrow} alt="" />
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="menu" onClick={handleToggle}>
          <img src={menu} alt="" />
        </div>

        <div className={isActive ? "active" : "mobile"}>
          <div className="width">
            <div onClick={handleToggle} className="logo">
              <img src={logo} alt="" />
            </div>

            <div className="menu" onClick={handleToggle}>
              <img src={close} alt="" />
            </div>
          </div>

          <div className="products ggg" onClick={handleToggle}>
            <Link to="/">Home</Link>
          </div>

          <div className="products" onClick={handleToggle}>
            <Link to="/about">About us</Link>
          </div>

          <div className="products" onClick={opencontent}>
            Our Services
          </div>
          <div className={iscontent1 ? "content" : "noncon"}>
            <div className="for" onClick={handleToggle}>
              <Link to="/website">
                <div className="image">
                  <img src={icon} alt="" />
                </div>
                <div className="oro">
                  <p>Website Design and Development</p>
                  <p></p>
                </div>
              </Link>
            </div>
            <div className="for" onClick={handleToggle}>
              <Link to="/social">
                <div className="image">
                  <img src={icon2} alt="" />
                </div>
                <div className="oro">
                  <p>Social Media Marketing</p>
                  <p></p>
                </div>
              </Link>
            </div>
            <div className="for" onClick={handleToggle}>
              <Link to="/seo">
                <div className="image">
                  <img src={icon3} alt="" />
                </div>
                <div className="oro">
                  <p>SEO and others</p>
                  <p></p>
                </div>
              </Link>
            </div>
          </div>
          <div className="products">Blog</div>

          <div className="products" onClick={handleToggle}>
            <Link to="/contact">
              <button> Contact Us</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
