import "./App.css";
import Footer from "./component/footer/Footer";
import Header from "./component/header/Header";
import Homepage from "./page/Homepage/Homepage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./page/about/About";
import Help from "./component/help/Help";
import Website from "./page/website/Website";
import Social from "./page/social/Social";
import Seo from "./page/social/Seo";
import Contact from "./page/contact/Contact";
import { useEffect } from "react";
import AOS from "aos";
import ScrollToTop from "./component/ScrollToTop";
import ReactGA from "react-ga";
// import { useState } from "react";

function App() {
  const tracking_id = "G-NSK3WNJD9L";
  ReactGA.initialize(tracking_id);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <ScrollToTop />
          <Header />
          <Homepage />
          <Help />
          <Footer />
        </>
      ),
    },
    {
      path: "about",
      element: (
        <>
          <ScrollToTop />
          <Header />
          <About />
          <Help />
          <Footer />
        </>
      ),
    },
    {
      path: "website",
      element: (
        <>
          <ScrollToTop />
          <Header />
          <Website />
          <Help />
          <Footer />
        </>
      ),
    },
    {
      path: "social",
      element: (
        <>
          <ScrollToTop />
          <Header />
          <Social />
          <Help />
          <Footer />
        </>
      ),
    },
    {
      path: "seo",
      element: (
        <>
          <ScrollToTop />
          <Header />
          <Seo />
          <Help />
          <Footer />
        </>
      ),
    },
    {
      path: "contact",
      element: (
        <>
          <ScrollToTop />
          <Header />
          <Contact />

          <Footer />
        </>
      ),
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
