import React from "react";
import "./sb.css";
import logo from "../../images/home/Group 109 (1).png";

export default function Sb() {
  return (
    <div className="sb" data-aos="fade-up" data-aos-duration="1000">
      <div className="width">
        <p>Some businesses We’ve Worked with</p>
        <div className="image">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
}
