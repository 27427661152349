import React from "react";
import "./homebody.css";
import Grow from "../grow/Grow";

export default function Homebody() {
  return (
    <div className="homebody">
      <div className="width">
        <div className="talk" data-aos="fade-up" data-aos-duration="1000">
          <div className="nigeria">
            <p>Nigeria’s #1 Digital Marketing Agency</p>
            <p>Give our customers the best digital marketing services</p>
          </div>
        </div>
        <div className="talk " data-aos="fade-up" data-aos-duration="1000">
          <Grow />
        </div>
      </div>
    </div>
  );
}
