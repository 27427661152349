import React from "react";
import syno from "../../images/services/syno.png";
import anc from "../../images/services/anc.png";
import ent from "../../images/services/ent.png";
import rex from "../../images/services/rex.jpg";
import spe from "../../images/services/spen.png";
import "./some.css";

export default function Some() {
  return (
    <div className="some">
      <div className="width">
        <div className="talk">
          <div className="work">Some Of Our Works</div>

          <div className="project">
            <div className="box">
              <img src={anc} alt="" />
              <a href="https://getanchor.co/">
                <div className="shadow1">
                  <p>Anchor </p>
                  <p>Visit Website </p>
                </div>
              </a>
            </div>

            <div className="box">
              <img src={rex} alt="" />
              <a href="https://www.rexafrica.com/">
                <div className="shadow1">
                  <p>Rex Africa </p>
                  <p>Visit Website </p>
                </div>
              </a>
            </div>

            <div className="box">
              <img src={syno} alt="" />
              <a target="blank" href="http://synofordengineering.com/">
                <div className="shadow1">
                  <p>Synoford Engineering </p>
                  <p>Visit Website </p>
                </div>
              </a>
            </div>

            <div className="box">
              <img src={ent} alt="" />
              <a href="https://enterprisengr.netlify.app/">
                <div className="shadow1">
                  <p>Netlify Enterprise </p>
                  <p>Visit Website </p>
                </div>
              </a>
            </div>

            <div className="box">
              <img src={syno} alt="" />
              <a href="https://thehealthyheartfoundation.org/">
                <div className="shadow1">
                  <p>The Healthy Heart </p>
                  <p>Visit Website </p>
                </div>
              </a>
            </div>

            <div className="box">
              <img src={spe} alt="" />
              <a href="https://spenn.netlify.app/">
                <div className="shadow1">
                  <p>Spen</p>
                  <p>Visit Website </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
