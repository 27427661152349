import React, { useEffect } from "react";
import ReactGA from "react-ga";
import "./about.css";
import arrow from "../../images/Group 118.svg";
import Enter from "../../component/enter/Enter";
import shake from "../../images/about/things.png";
import Leading from "../../component/leading/Leading";
import Mission from "../../component/mission/Mission";
import adeola from "../../images/about/Rectangle 56.png";
import fa from "../../images/about/Facebook F.svg";
import tw from "../../images/about/Twitter.svg";
import ig from "../../images/about/Instagram.svg";
import { Link } from "react-router-dom";

export default function About() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="about">
      <div className="homebody">
        <div className="width">
          <div className="talk">
            <div className="nigeria">
              <p data-aos="fade-up" data-aos-duration="1000">
                About Us
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                The Best Digital Agency For Business Solution
              </p>
            </div>
          </div>
          <div className="talk">{/* <Grow /> */}</div>
        </div>
      </div>
      <div className="pattern"></div>

      <Enter />
      <Leading />
      <Mission />

      <div className="enter reverse">
        <div className="width">
          <div className="pictalk">
            <div className="pic" data-aos="fade-left" data-aos-duration="2000">
              <img src={shake} alt="" />
            </div>
            <div
              className="talk"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="content">
                <p>Over 1,000 Projects Completed Worldwide</p>
                <p>
                  At Enzo, we're proud to have completed over 1,000 projects
                  worldwide, which demonstrates our extensive experience and
                  expertise in digital marketing. With this level of
                  accomplishment, businesses can trust that we have the skills
                  and knowledge to create effective digital marketing campaigns
                  that deliver measurable results. Our track record of success
                  also shows that we have the ability to adapt to changing
                  market trends and customer preferences, which is critical for
                  businesses looking to stay ahead of the competition.
                </p>
                <Link to="/contact">
                  <button>
                    {" "}
                    <h5>Contact Us</h5> <img src={arrow} alt="" />{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pattern2"></div>

      <div className="testimonial">
        <div className="width">
          <div className="long">
            <div className="talk">
              <p data-aos="fade-up" data-aos-duration="1000">
                Our Team
              </p>
              <p data-aos="fade-up" data-aos-duration="1000">
                Meet With Enzo <br /> Team Members
              </p>
            </div>
          </div>
          <div className="team">
            <div className="box" data-aos="fade-up" data-aos-duration="1000">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={adeola}
                alt=""
              />

              <div className="ok">
                <p data-aos="fade-up" data-aos-duration="1000">
                  Founder{" "}
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  Olutoke Adeola
                </p>
                <div className="o" data-aos="fade-up" data-aos-duration="1000">
                  <div className="solid"></div>
                  <img src={fa} alt="" />
                  <img src={tw} alt="" />
                  <img src={ig} alt="" />
                </div>
              </div>
            </div>
            <div className="box" data-aos="fade-up" data-aos-duration="1000">
              <img
                src="./photo_2023-06-05_15-04-28.jpg"
                alt=""
                data-aos="fade-up"
                data-aos-duration="1000"
              />

              <div className="ok">
                <p data-aos="fade-up" data-aos-duration="1000">
                  {" "}
                  Director
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  Nwakeredu Churchill
                </p>
                <div className="o" data-aos="fade-up" data-aos-duration="1000">
                  <div className="solid"></div>
                  <img src={fa} alt="" />
                  <img src={tw} alt="" />
                  <img src={ig} alt="" />
                </div>
              </div>
            </div>
            <div className="box" data-aos="fade-up" data-aos-duration="1000">
              <img
                src="./photo_2023-06-05_15-20-50.jpg"
                alt=""
                data-aos="fade-up"
                data-aos-duration="1000"
              />

              <div className="ok">
                <p data-aos="fade-up" data-aos-duration="1000">
                  C.T.O
                </p>
                <p data-aos="fade-up" data-aos-duration="1000">
                  Ike Rich
                </p>
                <div className="o" data-aos="fade-up" data-aos-duration="1000">
                  <div className="solid"></div>
                  <img src={fa} alt="" />
                  <img src={tw} alt="" />
                  <img src={ig} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
